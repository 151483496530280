<template>
  <div class="flex-column" v-loading="loading">
    <el-menu class="navbar" mode="horizontal">
      <div class="logo">
        <img class="user-avatar" :src="logo">量测水远传监控平台 —— 单位管理系统
      </div>
      <div class="navbar_right">
        <!-- 个人中心 -->
        <el-dropdown class="avatar-container" @command="handleCommand" trigger="hover" placement="top">
          <div class="avatar-wrapper">
            欢迎您，单位管理员
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu class="user-dropdown" slot="dropdown">
            <el-dropdown-item command="logout" divided>
              <span class="personal_icon iconfont icon-tuichu"></span>
              <span>退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="clear"></div>
      </div>
    </el-menu>
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$basicSystemConfigYhglSearchs" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;width: 50%;float: left;"></base-search>
        <div style="width: 49%;float: left;text-align: right;margin-right: 1%;padding-top: 5px;">
          <el-button type="success" size="mini" @click="addUser()">新增</el-button>
        </div>
        
      </div>
    </sticky>
    <div class="app-container flex-item">
      <el-table ref="mainTable" :key='tableKey' :data="list"  border fit highlight-current-row style="width: 100%;" height="calc(100% - 52px)" @row-click="rowClick" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"> </el-table-column>
        <el-table-column :label="'单位名称'" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column :label="'单位名称（4位缩写）'" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.code}}</span>
          </template>
        </el-table-column> -->
        <el-table-column :label="'登录名'" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.account}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'密码'" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.password}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'联系电话'" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.tel}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'操作'" class-name="small-padding fixed-width" width="220">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
            <el-button size="mini" type="warning" @click="handleCheck(scope.row)">记录</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"   @pagination="handleCurrentChange" />
    </div>
    
    <el-dialog title="维护单位" :visible.sync="addDialog" width="30%" v-loading="loading">
      <el-form ref="ruleForm" :model="addForm" label-width="120px" :rules="rules">
        <el-form-item label="单位名称" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <!-- <el-form-item label="单位名称（4位缩写）" prop="code">
          <el-input v-model="addForm.code"></el-input>
        </el-form-item> -->
        <el-form-item label="登录名" prop="account" >
          <el-input v-model="addForm.account" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input v-model="addForm.tel"></el-input>
        </el-form-item>
        <p style="font-size: 12px;color: red;"><span style="color: red;">*</span>Tips:admin为默认管理员用户,用户信息请登录系统基础资料-系统管理-用户管理维护</p>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="addDialog = false">取 消</el-button>
        <el-button type="primary" v-if="isAdd" @click="confirmAdd('ruleForm')">确 定</el-button>
        <el-button type="primary" v-if="!isAdd" @click="confirmEdit('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看记录" :visible.sync="checkDialog" width="50%">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="index" width="50" align="center"> </el-table-column>
        <el-table-column prop="remark" label="记录内容" align="center"> </el-table-column>
        <el-table-column prop="createUserName" label="操作人"  align="center"> </el-table-column>
        <el-table-column prop="createTime" label="操作时间" align="center"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Sticky from '@/components/Sticky'
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import * as users from '@/api/users'
  import { mapGetters, mapActions } from 'vuex'
  import logoPng from '@/assets/logo.png?imageView2/1/w/80/h/80'
  import waves from '@/directive/waves' // 水波纹指令
  import { local } from '@/utils'
  import {
    
  } from 'vuex'
  export default {
    name: 'zhgl',//单位管理
    components: {
      Sticky,
    },
    directives: {
      waves
    },
    data() {
      var validateInput = (rule, value, callback) => {  
        if (!value || value.length !== 4 || !/^[A-Z]{4}$/.test(value)) {  
          callback(new Error('请输入4个大写字母'));  
        } else {  
          callback();  
        }  
      }; 
      var validatePhone = (rule, value, callback) => {  
        const reg = /^1[3-9][0-9]\d{8}$/; // 定义正则表达式，匹配手机号格式  
        if (!value || !reg.test(value)) {  
          callback(new Error('请输入正确的手机号'));  
        } else {  
          callback();  
        }  
      };  
      return {
        loading:false,
        logo: logoPng,
        tableKey: 0,
        list: [],
        total: 0,
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          key: undefined
        },
        addDialog:false,//
        addForm:{
          name:'',//单位名称
          code:'',//单位账号简写
          account:'admin',//登录名
          password:'admin',//密码
          tel:'',//联系电话
        },
        rules:{
          name: [
            // { required: true, message: '请输入单位名称', trigger: 'blur' },
            { validator: validateInput, trigger: 'blur',required: true} 
          ],
          code: [
            { validator: validateInput, trigger: 'blur',required: true} 
          ],
          account: [
            { required: true, message: '请输入登录名号', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
          ],
          tel: [
            { validator: validatePhone, trigger: 'blur',required: true },
          ],
          
        },
        checkDialog:false,//
        tableData:[
          {address:'12313'}
        ],
        isAdd:true,//是否新增
        listQueryLog: { // 查询条件
          page: 1,
          limit: 9999,
          TenentId:'',
        },
      }
    },
    computed: {
      ...mapGetters(['sidebar', 'isIdentityAuth', 'name', 'themeStatus'])
    },
    mounted() {
      this.getList()
    },
    methods: {
      addUser(){
        this.isAdd = true
        this.addDialog = true
        this. addForm={
          name:'',//单位名称
          code:'',//单位账号简写
          account:'admin',//登录名
          password:'admin',//密码
          tel:'',//联系电话
        }
      },
      //新增提交
      confirmAdd(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading =true
            this.addForm.code = this.addForm.name
            basicDataApi.addTenants(this.addForm).then(response => {
              if(response.code == '200'){
                this.$message({
                  message:response.message,
                  type:'success'
                })
                this.addDialog = false
                this.getList()
                this.$refs['ruleForm'].resetFields();
              }else{
                this.$message({
                  message:response.message,
                  type:'warning'
                })
                this.list = []
              }
              this.loading =false
              this.$nextTick(() => {
                this.$refs.mainTable.doLayout();
              });
            }).catch(()=>{
              this.loading =false
            })
            
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      //编辑提交
      confirmEdit(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading =true
            this.addForm.code = this.addForm.name
            basicDataApi.editTenants(this.addForm).then(response => {
              if(response.code == '200'){
                this.$message({
                  message:response.message,
                  type:'success'
                })
                this.addDialog = false
                this.getList()
                this.$refs['ruleForm'].resetFields();
              }else{
                this.$message({
                  message:response.message,
                  type:'warning'
                })
                this.list = []
              }
              this.loading =false
              this.$nextTick(() => {
                this.$refs.mainTable.doLayout();
              });
            }).catch(()=>{
              this.loading =false
            })
            
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      handleUpdate(row){
        this.isAdd = false
        this.addForm = row
        this.addDialog = true
      },
      // 删除
      handleDelete(row){
        this.$confirm(`确定要删除当前单位吗?删除单位的同时会删除对应的数据库！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          this.loading =true
          var data = [row.id]
          basicDataApi.deleteTenants(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.loading =false
              this.getList()
              this.$refs['ruleForm'].resetFields();
            }else{
              this.loading =false
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
            this.loading =false
          }).catch(()=>{
            this.loading =false
          })
        }).catch(() => {
          this.loading =false
        });
      },
      handleCheck(row){
        this.listQueryLog.TenentId = row.id
        basicDataApi.zhglGetTenantsLog(this.listQueryLog).then(response => {
          if(response.code == 200){
            this.tableData = response.data
            this.checkDialog = true
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },
      handleCommand(name) {
        if(!name) return
        this[name]()
      },
      logout() {
        this.$store.dispatch('LogOut').then(() => {
          localStorage.removeItem("userCode")
          this.$router.push({
            path: '/'
          })
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleSizeChange(val) {
        this.listQuery.limit = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      getList() {
        basicDataApi.zhglGetTenants(this.listQuery).then(response => {
          if(response.code == '200'){
            this.list = response.data.filter(i=>i.code != 'OpenAuthDBContext')//过滤默认单位
            this.total = response.count
            this.list.forEach(i=>{
              i.account = 'admin'
              i.password = 'admin'
            })
          }else{
            this.$message({
              message:response.message,
              type:'warning'
            })
            this.list = []
          }
          this.$nextTick(() => {
            this.$refs.mainTable.doLayout();
          });
        }).catch(()=>{
        })
      },
      rowClick(row) {
        this.$refs.mainTable.clearSelection()
        this.$refs.mainTable.toggleRowSelection(row)
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnAdd':
            this.handleCreate()
            break
          case 'btnEdit':
            
            break
          case 'btnDel':
            
            break
          case 'btnAccessRole':
            if (this.multipleSelection.length !== 1) {
              this.$message({
                message: '只能选中一个进行编辑',
                type: 'error'
              })
              return
            }
            this.handleAccessRole(this.multipleSelection[0])
            break
            case 'btnThirdPartyMes':
            if (this.multipleSelection.length <=0) {
              this.$message({
                message: '至少选择一个进行维护',
                type: 'error'
              })
              return
            }
           this.getDiaData()
            break
          default:
            break
        }
      },
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss">
  .navbar .logo,.navbar .logo .user-avatar{
    padding-left: 5px;
    width: auto;
    line-height: 44px;
  }
  .el-dropdown-menu__item--divided{
    border-top: none !important;
  }
</style>
