var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column",
    },
    [
      _c("el-menu", { staticClass: "navbar", attrs: { mode: "horizontal" } }, [
        _c("div", { staticClass: "logo" }, [
          _c("img", { staticClass: "user-avatar", attrs: { src: _vm.logo } }),
          _vm._v("量测水远传监控平台 —— 单位管理系统 "),
        ]),
        _c(
          "div",
          { staticClass: "navbar_right" },
          [
            _c(
              "el-dropdown",
              {
                staticClass: "avatar-container",
                attrs: { trigger: "hover", placement: "top" },
                on: { command: _vm.handleCommand },
              },
              [
                _c("div", { staticClass: "avatar-wrapper" }, [
                  _vm._v(" 欢迎您，单位管理员 "),
                  _c("i", { staticClass: "el-icon-caret-bottom" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "user-dropdown",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "logout", divided: "" } },
                      [
                        _c("span", {
                          staticClass: "personal_icon iconfont icon-tuichu",
                        }),
                        _c("span", [_vm._v("退出登录")]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "clear" }),
          ],
          1
        ),
      ]),
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("base-search", {
              staticStyle: {
                display: "inline-block",
                width: "50%",
                float: "left",
              },
              attrs: {
                searchList: _vm.$basicSystemConfigYhglSearchs,
                listQuery: _vm.listQuery,
              },
              on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
            }),
            _c(
              "div",
              {
                staticStyle: {
                  width: "49%",
                  float: "left",
                  "text-align": "right",
                  "margin-right": "1%",
                  "padding-top": "5px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "success", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.addUser()
                      },
                    },
                  },
                  [_vm._v("新增")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "app-container flex-item" },
        [
          _c(
            "el-table",
            {
              key: _vm.tableKey,
              ref: "mainTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": "",
                height: "calc(100% - 52px)",
              },
              on: {
                "row-click": _vm.rowClick,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "单位名称", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "登录名",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.account))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "密码",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.password))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "联系电话",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.tel))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "class-name": "small-padding fixed-width",
                  width: "220",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "warning" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCheck(scope.row)
                              },
                            },
                          },
                          [_vm._v("记录")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { title: "维护单位", visible: _vm.addDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.addDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.addForm,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "单位名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "name", $$v)
                      },
                      expression: "addForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "登录名", prop: "account" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.addForm.account,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "account", $$v)
                      },
                      expression: "addForm.account",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.addForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "password", $$v)
                      },
                      expression: "addForm.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话", prop: "tel" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addForm.tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "tel", $$v)
                      },
                      expression: "addForm.tel",
                    },
                  }),
                ],
                1
              ),
              _c("p", { staticStyle: { "font-size": "12px", color: "red" } }, [
                _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                _vm._v(
                  "Tips:admin为默认管理员用户,用户信息请登录系统基础资料-系统管理-用户管理维护"
                ),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.addDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm.isAdd
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmAdd("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
              !_vm.isAdd
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmEdit("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "查看记录", visible: _vm.checkDialog, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.checkDialog = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "50", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "记录内容", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createUserName",
                  label: "操作人",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "操作时间",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.checkDialog = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }